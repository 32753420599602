import React from 'react';

function Footer() {
  return (
    <footer>
      <p>Developed by <a href="https://www.linkedin.com/in/nikolagajicbusiness/">Nikola Gajić</a></p>
    </footer>
  );
}

export default Footer;
