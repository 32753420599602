// src/Header.js
import React from 'react';
// import { Navigate, useNavigate } from 'react-router-dom';
import './index.css'; 
import logoprince from './public/prince_logo.png';
import sideralogo from './public/sidera_logo.png';


function Header() {
  const handleLogout = () => {
    localStorage.removeItem('user');
    window.location=('/login'); // Izmena rutiranja na uspeh
    
  };

  return (
    <div className="header">
      <img src={logoprince} alt="Prince Logo" className="prince-logo" />
      <div className="right-section">
        <button onClick={handleLogout} className="logout-button">Logout</button>
        <a href="https://sidera-rs.com/"><img src={sideralogo}  alt="SIDERA logo" className="sidera-logo" /></a>
      </div>
    </div>
  );
}

export default Header;
