import React from 'react';
import { useNavigate } from 'react-router-dom';
import './index.css';
import './Header';

function MainMenu({ startQuiz }) {
  const navigate = useNavigate();

  return (
    <div className="main-menu">
      <h1>PRINCE2 Foundation</h1>
      <button className='dugme' onClick={() => startQuiz(0, navigate)}>Start Quiz 1</button>
      <button className='dugme' onClick={() => startQuiz(1, navigate)}>Start Quiz 2</button>
    </div>
  );
}

export default MainMenu;
